
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93rvBcNmVYfHMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/[...slug].vue?macro=true";
import { default as _404iFKnFEBKtGMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/404.vue?macro=true";
import { default as _91_91filter_93_934AuLInR9JYMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/account/bookings/[[filter]].vue?macro=true";
import { default as indexHjmInkV8o0Meta } from "/codebuild/output/src1750150582/src/gx-booking/pages/account/index.vue?macro=true";
import { default as notifications5dJ5pLEznuMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/account/notifications.vue?macro=true";
import { default as paymentsm3ekVHJY66Meta } from "/codebuild/output/src1750150582/src/gx-booking/pages/account/payments.vue?macro=true";
import { default as personal_45infof06JNQCVWaMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/account/personal-info.vue?macro=true";
import { default as securityWKFTzolXMtMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/account/security.vue?macro=true";
import { default as confirmzLfnUePvReMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue?macro=true";
import { default as indexzxrRaNNbgwMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue?macro=true";
import { default as _91bookingId_93gAgZ2TqL8jMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue?macro=true";
import { default as _91accessKey_93saT89MUzQFMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/gratuity/[accessKey].vue?macro=true";
import { default as loginipX7ZCFdd7Meta } from "/codebuild/output/src1750150582/src/gx-booking/pages/login.vue?macro=true";
import { default as reset_45passwordQioLwj4dP4Meta } from "/codebuild/output/src1750150582/src/gx-booking/pages/reset-password.vue?macro=true";
import { default as set_45passwordcxVVdTGxZWMeta } from "/codebuild/output/src1750150582/src/gx-booking/pages/set-password.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93rvBcNmVYfHMeta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/[...slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/404.vue")
  },
  {
    name: "account-bookings-filter",
    path: "/account/bookings/:filter?",
    meta: _91_91filter_93_934AuLInR9JYMeta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/account/bookings/[[filter]].vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexHjmInkV8o0Meta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/account/index.vue")
  },
  {
    name: "account-notifications",
    path: "/account/notifications",
    meta: notifications5dJ5pLEznuMeta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/account/notifications.vue")
  },
  {
    name: "account-payments",
    path: "/account/payments",
    meta: paymentsm3ekVHJY66Meta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/account/payments.vue")
  },
  {
    name: "account-personal-info",
    path: "/account/personal-info",
    meta: personal_45infof06JNQCVWaMeta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/account/personal-info.vue")
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityWKFTzolXMtMeta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/account/security.vue")
  },
  {
    name: "experiences-parentId-serviceId-confirm",
    path: "/experiences/:parentId()/:serviceId()/confirm",
    meta: confirmzLfnUePvReMeta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue")
  },
  {
    name: "experiences-parentId-serviceId",
    path: "/experiences/:parentId()/:serviceId()",
    meta: indexzxrRaNNbgwMeta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue")
  },
  {
    name: "experiences-parentId-serviceId-return-bookingId",
    path: "/experiences/:parentId()/:serviceId()/return/:bookingId()",
    meta: _91bookingId_93gAgZ2TqL8jMeta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue")
  },
  {
    name: "gratuity-accessKey",
    path: "/gratuity/:accessKey()",
    meta: _91accessKey_93saT89MUzQFMeta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/gratuity/[accessKey].vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginipX7ZCFdd7Meta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/login.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordQioLwj4dP4Meta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/reset-password.vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: set_45passwordcxVVdTGxZWMeta || {},
    component: () => import("/codebuild/output/src1750150582/src/gx-booking/pages/set-password.vue")
  }
]