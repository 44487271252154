import validate from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_encoding@0.1.13_eslint@_e9226b8e9032f7769d0bd18ecbf5629c/node_modules/nuxt/dist/pages/runtime/validate.js";
import account_45context_45slug_45global from "/codebuild/output/src1750150582/src/gx-booking/middleware/account-context-slug.global.ts";
import auth_45check_45global from "/codebuild/output/src1750150582/src/gx-booking/middleware/auth-check.global.ts";
import layout_45switcher_45global from "/codebuild/output/src1750150582/src/gx-booking/middleware/layout-switcher.global.ts";
import trailing_45slash_45global from "/codebuild/output/src1750150582/src/gx-booking/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_encoding@0.1.13_eslint@_e9226b8e9032f7769d0bd18ecbf5629c/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  account_45context_45slug_45global,
  auth_45check_45global,
  layout_45switcher_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}