import payload_plugin_Z0cFW4pZ8F from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_HCIjYxNr1s from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_encoding@0.1.13_eslint@_e9226b8e9032f7769d0bd18ecbf5629c/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Sh5B2c2UAo from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_encoding@0.1.13_eslint@_e9226b8e9032f7769d0bd18ecbf5629c/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hv8ZR7fQuj from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_encoding@0.1.13_eslint@_e9226b8e9032f7769d0bd18ecbf5629c/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_j10FwsVfA9 from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_encoding@0.1.13_eslint@_e9226b8e9032f7769d0bd18ecbf5629c/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_y5J0irmFYz from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_encoding@0.1.13_eslint@_e9226b8e9032f7769d0bd18ecbf5629c/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bsgosg5LPe from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_encoding@0.1.13_eslint@_e9226b8e9032f7769d0bd18ecbf5629c/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zllu1JOG1E from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_encoding@0.1.13_eslint@_e9226b8e9032f7769d0bd18ecbf5629c/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_4bUGhTHvwx from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1750150582/src/gx-booking/.nuxt/components.plugin.mjs";
import prefetch_client_5a1QYuIMVq from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_encoding@0.1.13_eslint@_e9226b8e9032f7769d0bd18ecbf5629c/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_hjCrT3Mgqd from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_xrY8REeVYf from "/codebuild/output/src1750150582/src/gx-booking/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import firebase_Wjz9XrZo01 from "/codebuild/output/src1750150582/src/gx-booking/plugins/firebase.ts";
import middleware_global_fxkQLky8iN from "/codebuild/output/src1750150582/src/gx-booking/plugins/middleware.global.ts";
import vuedatepicker_oKNl9XPOyX from "/codebuild/output/src1750150582/src/gx-booking/plugins/vuedatepicker.ts";
export default [
  payload_plugin_Z0cFW4pZ8F,
  revive_payload_client_HCIjYxNr1s,
  unhead_Sh5B2c2UAo,
  router_hv8ZR7fQuj,
  payload_client_j10FwsVfA9,
  navigation_repaint_client_y5J0irmFYz,
  check_outdated_build_client_bsgosg5LPe,
  chunk_reload_client_zllu1JOG1E,
  plugin_vue3_4bUGhTHvwx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5a1QYuIMVq,
  switch_locale_path_ssr_hjCrT3Mgqd,
  i18n_xrY8REeVYf,
  firebase_Wjz9XrZo01,
  middleware_global_fxkQLky8iN,
  vuedatepicker_oKNl9XPOyX
]